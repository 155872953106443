<template>
  <div>
    <v-breadcrumbs :items="breadcrumbItems"/>

    <v-card v-if="org">

      <div>
        <v-alert v-if="mode == 'redirectFromPublicView'" type="info" tile class="mb-0">
          <p>As the admin of this organization you can manage the organization on this page.</p>
        </v-alert>
        <v-toolbar elevation="0" color="grey lighten-5">
          <v-toolbar-title class="ml-3 py-2 subtitle">
            Organization Details
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <div>
            <v-btn :to="{name:'orgmanagerEdit', params: {id: org.id}}" class="mr-2">
              <v-icon small class="mr-2">fadl fa fa-pencil</v-icon>
              Edit
            </v-btn>
          </div>
        </v-toolbar>
        <v-container class="info-container grey lighten-5 py-0">
          <v-row>
            <v-col cols="12" sm="6">
              <div class="label">ID</div>
              <div class="value"><pre>{{org.id}}</pre></div>
            </v-col>
          </v-row>
        </v-container>
        <div v-if="org">
          <v-alert v-if="mode == 'new'" type="success" tile>
            <p>Organization is created successfully.</p>
            <v-btn :to="{name:'eventmanagerCreate', query: {groupId: org.id}}">Create Event</v-btn> 
          </v-alert>
          <v-card-title>Active Events</v-card-title>
          <EventGrid v-if="activeEvents && activeEvents.length" :routeResolver="resolveEventManagerRoute" :items="activeEvents" icon="fa-pencil-alt" :minimal="false" class="mx-4 mb-4"></EventGrid>
          
          <v-card-title v-if="upcomingEvents && upcomingEvents.length">Upcoming Events</v-card-title>
          <EventGrid v-if="upcomingEvents && upcomingEvents.length" :routeResolver="resolveEventManagerRoute" :items="upcomingEvents" icon="fa-pencil-alt" :minimal="false" class="mx-4 mb-4"></EventGrid>

          <v-card-text v-if="(!activeEvents || !activeEvents.length) && (!upcomingEvents || !upcomingEvents.length)">
            No active or upcoming events currently.
          </v-card-text>
          <v-card-actions>
            <v-btn outlined :color="org.color || 'primary'" :to="{name:'eventmanagerCreate', query: {groupId: org.id}}">Create Event</v-btn> 
            <v-btn text :color="org.color || 'primary'" :to="{name: 'orgmanagerEvents', params: {id: org.id}}">All Events</v-btn> 
          </v-card-actions>
        </div>

        <v-row v-if="org && stats & false">
          <v-col cols="12" class="my-0 py-0">
            <v-card-title class="mb-0"><strong class="mr-1">{{stats.participants}}</strong> out of <strong class="mx-1">{{stats.registrations}}</strong> users have joined this organization.</v-card-title>
          </v-col>
          <v-col cols="6">
            <v-subheader>Users per day</v-subheader>
            <v-sparkline
              :color="org.color || 'primary'"
              :value="Object.values(stats.participants_by_date)"
              smooth
              line-width="2"
              />
          </v-col>
          <v-col cols="6">
            <v-subheader>Registrations per day</v-subheader>

            <v-sparkline
              :color="org.color || 'primary'"
              :value="Object.values(stats.registrations_by_date)"
              smooth
              />
          </v-col>
          <v-col cols="12" class="my-0 py-0">
            <v-card-text class="text-muted">Data of last 14 days.</v-card-text>
          </v-col>
        </v-row>

        <div v-if="tenant.id==='cofi' && licenseInfo">
          <v-card-title class="subtitle">
            <span class="mr-2">Subscription Plan</span>
            <span class="mr-2 font-weight-bold">{{ licenseInfo.license | license }}</span>
            <v-btn v-if="org.license == 'FREE'" text color="primary" :to="{name: 'orgmanagerSubscription', params: {id: org.id}}">Upgrade</v-btn>            
          </v-card-title>
          <v-card-text v-if="licenseInfo">
            <p v-if="licenseInfo.valid">
              <v-icon color="green">fa-check-circle</v-icon>
              Valid subscription. 
              Renewal date: <span class="mr-2 font-weight-bold">{{ org.subscription_renewal | localDate }}</span>
            </p>
            <v-alert v-else type="error" prominent>
              <p>All seats used or subscription has expired. No new users can join this challenge until new seats are purchased.</p>
              <p>
                Renewal date: <span class="mr-2 font-weight-bold">{{ org.subscription_renewal | localDate }}</span>
              </p>
              <p>
                <v-btn :to="{name: 'orgmanagerSubscription', params: {id: org.id}}">Purchase Now</v-btn>                            
              </p>
            </v-alert>
            <div class="d-flex flex-row">
              <v-progress-linear striped class="ma--4" height="25" color="green" :value="Math.round(licenseInfo.current_capacity / licenseInfo.max_capacity * 100)" />
              <div class="shrink mt-0 ml-4" style="white-space:nowrap;">{{ licenseInfo.max_capacity }} users</div>
            </div>
            <span class="text-muted">{{ licenseInfo.current_capacity }} users out of {{ licenseInfo.max_capacity }} available users. Actual number of active users might have a slight processing delay.</span>
          </v-card-text>
          <v-card-text v-else>
            Loading...
          </v-card-text>
          <v-card-actions>
            <v-btn text :to="{name: 'orgmanagerSubscription', params: {id: org.id}}">Manage Subscription</v-btn>            
          </v-card-actions>
        </div>

      </div>   
    </v-card>
  </div>      
</template>

<script>
import Vue from "vue";
import { mapGetters } from "vuex";
import orgManagerService from "@/services/orgManagerService";
import { EventBus } from '@/plugins/eventbus.js';
import siteData from '@/data/site.json'
import EventGrid from "@/components/EventGrid.vue";
import OrgUtil from "@/util/orgUtil";
import tenants from '@/data/tenants.config'
const tenant = tenants.current();

export default {
  name: "Org",
  components: {
    EventGrid,
  },
  props: {
  },
  data() {
    return {
      tenant: tenant,
      error: null,
      message: null,
      breadcrumbItems: [
        { text: 'Event Manager', exact: true, to: { name: 'eventmanager' } },
        { text: 'Organizations', exact: true, to: { name: 'orgmanager' } },
        { text: 'Details', disabled: true },
      ],
      mode: null,
      org: null,
      licenseInfo: null,
      stats: null,
      events: null,
    };
  },
  async mounted() {
    this.mode = this.$route.query.mode;
    await this.getData();
    
    EventBus.$on('login-state-change', async user => {
      await this.getData();
    });
    EventBus.$on('orgmanager-refresh', async state => {
      console.log('refresh requested', state);
      this.org = state.org; // force refresh down the tree
      this.events = this.org.events || [];
    });
  },
  methods: {
    async getGroup(id) {
      var response = await orgManagerService.get(id);
      this.org = response.data;
      this.events = this.org.events || [];

      this.events = (await orgManagerService.getEvents(id)).data.data;
      this.licenseInfo = (await orgManagerService.getLicenseStatus(id)).data;
      this.stats = (await orgManagerService.getStats(id)).data;
    },

    async getData() {
      if (this.user) {
        await this.getGroup(this.$route.params.id);
      }
    },


    resolveEventManagerRoute(eventId) {
      return {name: 'eventmanagerView', params: {id: eventId}}
    },


  },
  computed: {
    orgUtil() {
      return new OrgUtil(this, this.org);
    },
    activeEvents() {
      return this.events && this.events.filter(x => this.$helpers.isActiveEvent(x)).sort((a,b) => a.from > b.from);
    },
    upcomingEvents() {
      return this.events && this.events.filter(x => this.$helpers.isFutureEvent(x)).sort((a,b) => a.from > b.from);
    },
    ...mapGetters({
      user: "user"
    })
  },
  watch: {
  },
  async beforeRouteUpdate (to, from, next) {
    // react to route changes...
    await this.getGroup(to.params.id);
    next();
  },
};
</script>
<style lang="scss">
  .full-dialog { 
      width:100vw; 
      height: 100vh;
      /*.toastui-editor-defaultUI { position: absolute; top:0; bottom: 0; right: 0; left: 0;}*/
    }
</style>

